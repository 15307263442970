var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("b-card", [
        _c(
          "div",
          { staticClass: "vo-column", staticStyle: { width: "100%" } },
          [
            _c("div", { staticClass: "productDetailRow" }, [
              _c("div", { staticClass: "productDetailColumn" }, [
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productDetailContentColor" }, [
                    _c("strong", [_vm._v("Brand Name:")])
                  ]),
                  _c("p", { staticClass: "productDetailContent" }, [
                    _vm._v(_vm._s(_vm.info.brandName))
                  ])
                ]),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productDetailContentColor" }, [
                    _c("strong", [_vm._v("Business Type:")])
                  ]),
                  _c("p", { staticClass: "productDetailContent" }, [
                    _vm._v(_vm._s(_vm.info.companyBusinessId))
                  ])
                ]),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productDetailContentColor" }, [
                    _c("strong", [_vm._v("Social Media Url:")])
                  ]),
                  _c(
                    "a",
                    {
                      staticClass: "productDetailContent",
                      attrs: { href: _vm.info.brandIns }
                    },
                    [_vm._v(_vm._s(_vm.info.brandIns))]
                  )
                ]),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productDetailContentColor" }, [
                    _c("strong", [_vm._v("Brand Official Website:")])
                  ]),
                  _c(
                    "a",
                    {
                      staticClass: "productDetailContent",
                      attrs: { href: _vm.info.brandUrl }
                    },
                    [_vm._v(_vm._s(_vm.info.brandUrl))]
                  )
                ]),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productDetailContentColor" }, [
                    _c("strong", [_vm._v("Company Introduction:")])
                  ]),
                  _c(
                    "div",
                    { staticClass: "productDetailContent" },
                    [
                      _c(
                        "b-row",
                        { staticStyle: { "margin-left": "-10px" } },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "8" } },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "company-introduction",
                                  placeholder: _vm.info.brandIntroduction,
                                  disabled: true,
                                  rows: "10",
                                  "max-rows": "10",
                                  maxlength: "2000"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "vo-row",
                    staticStyle: { "padding-top": "10px" }
                  },
                  [
                    _c("p", { staticClass: "productDetailContentColor" }, [
                      _c("strong", [_vm._v("Brand:")])
                    ]),
                    _c(
                      "div",
                      { staticClass: "productDetailContent" },
                      [
                        _c(
                          "b-row",
                          {
                            staticStyle: { "margin-left": "-10px" },
                            attrs: { "cols-xl": "5" }
                          },
                          [
                            _c(
                              "b-col",
                              { staticClass: "pd cursor" },
                              [
                                _c("ImageComponets", {
                                  attrs: { image: _vm.info.brandPicture }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productDetailContentColor" }, [
                    _c("strong", [_vm._v("Status:")])
                  ]),
                  _c("p", { staticClass: "productDetailContent" }, [
                    _vm._v(_vm._s(_vm.enableStatusText))
                  ])
                ]),
                _c("div", { staticClass: "vo-row" }, [
                  _c("p", { staticClass: "productDetailContentColor" }, [
                    _c("strong", [_vm._v("Review Status:")])
                  ]),
                  _c("p", { staticClass: "productDetailContent" }, [
                    _vm._v(_vm._s(_vm.reviewStatusText))
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _vm.opertionType == 2
        ? _c(
            "b-form",
            { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-row",
                    { staticClass: "align-items-center" },
                    [
                      _c("b-col", { attrs: { lg: "12" } }, [
                        _c("div", { staticClass: "blockquote" }, [
                          _c("h4", [_vm._v("Review Opinion")])
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "vo-column",
                      staticStyle: { width: "100%" }
                    },
                    [
                      _c("div", { staticClass: "productDetailRow" }, [
                        _c("div", { staticClass: "productDetailColumn" }, [
                          _c("div", { staticClass: "vo-row" }, [
                            _c(
                              "p",
                              { staticClass: "productDetailContentColor" },
                              [_c("strong", [_vm._v("Review Status:")])]
                            ),
                            _c(
                              "p",
                              { staticClass: "productDetailContent" },
                              [
                                _c("b-form-radio-group", {
                                  attrs: {
                                    id: "radio-group-1",
                                    options: _vm.reviewStatus,
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.status,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "status", $$v)
                                    },
                                    expression: "form.status"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "vo-row" }, [
                            _c(
                              "p",
                              { staticClass: "productDetailContentColor" },
                              [_c("strong", [_vm._v("Review Instructions:")])]
                            ),
                            _c(
                              "p",
                              { staticClass: "productDetailContent" },
                              [
                                _c(
                                  "b-row",
                                  { staticStyle: { "margin-left": "-10px" } },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "8" } },
                                      [
                                        _c(
                                          "FormRow",
                                          {
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "right",
                                                  fn: function() {
                                                    return [
                                                      _c("CountText", {
                                                        attrs: {
                                                          len:
                                                            _vm.form
                                                              .reviewInstructions
                                                              .length,
                                                          max: 200
                                                        }
                                                      })
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              false,
                                              3603033782
                                            )
                                          },
                                          [
                                            _c("b-form-textarea", {
                                              attrs: {
                                                id: "instructions",
                                                placeholder:
                                                  "Enter Review Instructions",
                                                rows: "10",
                                                "max-rows": "10",
                                                maxlength: "2000"
                                              },
                                              model: {
                                                value:
                                                  _vm.form.reviewInstructions,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "reviewInstructions",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.reviewInstructions"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "3" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "custom-width",
                      attrs: { type: "submit", variant: "primary" }
                    },
                    [_vm._v("Save")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-4 custom-width",
                      attrs: { type: "reset" }
                    },
                    [_vm._v("Cancel ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.opertionType == 1
        ? _c(
            "b-form-group",
            { attrs: { "label-cols-lg": "2" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "custom-width",
                  attrs: { type: "reset" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [_vm._v("Return ")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "Dialog",
        {
          attrs: {
            imageSrc: require("@/assets/success.png"),
            content: "Saved successfully",
            noBtn: "OK"
          },
          on: { cancel: _vm.cancel, hide: _vm.hide },
          model: {
            value: _vm.successAlear,
            callback: function($$v) {
              _vm.successAlear = $$v
            },
            expression: "successAlear"
          }
        },
        [_c("p", [_vm._v("Saved successfully")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }