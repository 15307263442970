<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-card>
      <div class="vo-column" style="width: 100%">
        <div class="productDetailRow">
          <div class="productDetailColumn">
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Brand Name:</strong>
              </p>
              <p class="productDetailContent">{{ info.brandName }}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Business Type:</strong>
              </p>
              <p class="productDetailContent">{{ info.companyBusinessId }}</p>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Social Media Url:</strong>
              </p>
              <a :href="info.brandIns" class="productDetailContent">{{
                info.brandIns
              }}</a>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Brand Official Website:</strong>
              </p>
              <a :href="info.brandUrl" class="productDetailContent">{{
                info.brandUrl
              }}</a>
            </div>
            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Company Introduction:</strong>
              </p>

              <div class="productDetailContent">
                <b-row style="margin-left: -10px">
                  <b-col cols="8">
                    <b-form-textarea
                      id="company-introduction"
                      :placeholder="info.brandIntroduction"
                      :disabled="true"
                      rows="10"
                      max-rows="10"
                      maxlength="2000"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
              </div>
            </div>

            <div class="vo-row" style="padding-top: 10px">
              <p class="productDetailContentColor">
                <strong>Brand:</strong>
              </p>
              <div class="productDetailContent">
                <b-row cols-xl="5" style="margin-left: -10px">
                  <b-col class="pd cursor">
                    <ImageComponets :image="info.brandPicture" />
                  </b-col>
                </b-row>
              </div>
            </div>

            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Status:</strong>
              </p>
              <p class="productDetailContent">{{ enableStatusText }}</p>
            </div>

            <div class="vo-row">
              <p class="productDetailContentColor">
                <strong>Review Status:</strong>
              </p>
              <p class="productDetailContent">{{ reviewStatusText }}</p>
            </div>
          </div>
        </div>
      </div>
    </b-card>

    <b-form @submit="onSubmit" @reset="onReset" v-if="opertionType == 2">
      <b-card>
        <b-row class="align-items-center">
          <b-col lg="12">
            <div class="blockquote">
              <h4>Review Opinion</h4>
            </div>
          </b-col>
        </b-row>
        <div class="vo-column" style="width: 100%">
          <div class="productDetailRow">
            <div class="productDetailColumn">
              <div class="vo-row">
                <p class="productDetailContentColor">
                  <strong>Review Status:</strong>
                </p>
                <p class="productDetailContent">
                  <b-form-radio-group
                    id="radio-group-1"
                    v-model="form.status"
                    :options="reviewStatus"
                    required
                  ></b-form-radio-group>
                </p>
              </div>
              <div class="vo-row">
                <p class="productDetailContentColor">
                  <strong>Review Instructions:</strong>
                </p>
                <p class="productDetailContent">
                  <b-row style="margin-left: -10px">
                    <b-col cols="8">
                      <FormRow>
                        <b-form-textarea
                          id="instructions"
                          v-model="form.reviewInstructions"
                          placeholder="Enter Review Instructions"
                          rows="10"
                          max-rows="10"
                          maxlength="2000"
                        ></b-form-textarea>
                        <template #right>
                          <CountText
                            :len="form.reviewInstructions.length"
                            :max="200"
                          />
                        </template>
                      </FormRow>
                    </b-col>
                  </b-row>
                </p>
              </div>
            </div>
          </div>
        </div>
      </b-card>
      <b-form-group label-cols-lg="3">
        <b-button type="submit" variant="primary" class="custom-width"
          >Save</b-button
        >
        <b-button type="reset" class="ml-4 custom-width">Cancel </b-button>
      </b-form-group>
    </b-form>

    <b-form-group label-cols-lg="2" v-if="opertionType == 1">
      <b-button type="reset" class="custom-width" @click="$router.back()"
        >Return
      </b-button>
    </b-form-group>

    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      content="Saved successfully"
      noBtn="OK"
      @cancel="cancel"
      @hide="hide"
    >
      <p>Saved successfully</p>
    </Dialog>

    <!-- <b-card v-if="info.reviewRecordList">
      <br />
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Review Opinion</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-col lg="8">
          <span class="p-3">
            <b-table
              style="display: inline; vertical-align: text-top"
              striped
              hover
              :items="info.reviewRecordList"
              :fields="fields"
            ></b-table>
          </span>
        </b-col>
      </b-container>
    </b-card> -->
  </Layout>
</template>
<style scoped>
.productDetailRow {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.productDetailColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.productDetailContent {
  text-align: left;
  flex: 1;
  margin-left: 5px;
}
.productDetailContentColor {
  width: 200px;
  text-align: right;
  color: #333333;
}
.pd {
  padding-bottom: 10px;
}
</style>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import { mapGetters } from "vuex";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import router from "@/router";
import ImageComponets from "@/components/brand/ImageComponets.vue";
import Dialog from "@/components/Dialog.vue";
import FormRow from "@/components/FormRow.vue";
import CountText from "@/components/CountText.vue";

/**
 * Cartridge Info component
 */
export default {
  components: {
    Layout,
    PageHeader,
    timeZoneConversion,
    ImageComponets,
    Dialog,
    FormRow,
    CountText,
  },
  data() {
    return {
      title: this.$route.query.opertionType == 1?"View Brand Info":"Review Brand",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "Client&Brand",
        },
        {
          text: "Brand Mgmt",
        },
        {
          text: this.$route.query.opertionType == 1?"View Brand Info":"Review Brand",
          active: true,
        },
      ],
      info: {},
      fields: [
        {
          key: "operationName",
          label: "Operation Name",
          class: "text-center",
        },
        {
          key: "status",
          label: "Operation Status",
          formatter: (value) => {
            return value == 1
              ? "Passed"
              : value == 2
              ? "Not pass"
              : "Pending review";
          },
        },
        {
          key: "reviewContent",
          label: "Review Content",
          class: "text-center",
        },
      ],
      show: true,
      form: {
        id: this.$route.query.id,
        status: 1,
        reviewInstructions: "",
      },
      opertionType: this.$route.query.opertionType,
      reviewStatus: [
        { text: "Passed", value: 1, disabled: false },
        { text: "Not pass", value: 2, disabled: false },
      ],
      successAlear: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    router() {
      return router;
    },
    init() {
      if (this.$route.query.id) {
        this.$api.Flavor.findBrandById({ id: this.$route.query.id }).then(
          (res) => {
            if (res.success) {
              this.info = res.data;
              if (res.data.companyBusinessId) {
                const business = res.data.companyBusinessId.split(",");
                const newBusiness = business.map((value) => {
                  if (value == 1) {
                    return "Nicotine";
                  } else if (value == 2) {
                    return "CBD/THC Concentrates";
                  }
                });
                this.info.companyBusinessId = newBusiness.join(",");
              }
            } else {
              if (res.message) {
                this.$bvToast.toast(res.message);
              }
            }
          }
        );
      }
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$api.Flavor.reviewBrandById(this.form).then((res) => {
        if (res.success) {
          this.successAlear = true;
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    cancel() {
      this.$router.go(-1);
    },
    hide() {
      this.$router.go(-1);
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        id: this.$route.query.id,
        status: "",
        reviewInstructions: "",
      };
      this.show = false;
      this.$nextTick(() => {
        this.$router.go(-1);
      });
    },
    getLocalTime(status, value) {
      return status == 1
        ? timeZoneConversion.getLocalTimes(value)
        : timeZoneConversion.getLocalTime(value);
    },
  },
  computed: {
    ...mapGetters(["isIkrusher", "isUnicoreus"]),
    reviewStatusText() {
      const status = this.info.status;
      if (status == 0) {
        return "Pending Review";
      } else if (status == 1) {
        return "Passed";
      } else if (status == 2) {
        return "No passed";
      }
    },
    enableStatusText() {
      const status = this.info.enableStatus;
      if (status == 0) {
        return "Eanable";
      } else {
        return "Disable";
      }
    },
  },
};
</script>